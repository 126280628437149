@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.select-field {
  position: relative;
  border-radius: 4px;
  user-select: none;
}
.select-field--black {
  background-color: #000;
  color: #fff;
}
.select-field--white {
  border: 1px solid #fff;
  background-color: #000;
  color: #fff;
}
.select-field .value {
  z-index: 2;
  display: flex;
  padding: 8px;
}
.select-field .value .select__icon {
  margin-left: auto;
  width: 15px;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.select-field .value .select__icon--close {
  transform: rotate(180deg);
}
.select-field .value--empty {
  height: 30px;
}
.select-field .list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 30px;
  max-height: 250px;
  width: 100%;
  border-bottom: solid 2px #ddd9d3;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  user-select: none;
}
.select-field .list--white {
  background-color: #fff;
  color: #000;
}
.select-field .list--black {
  background-color: #000;
}
.select-field .list .list-entries {
  flex-grow: 1;
  overflow-y: auto;
}
.select-field .list .list-entries .list__item {
  padding: 8px;
  font-size: 1.1rem;
  cursor: pointer;
}
.select-field .list .list-entries .list__item:last-child {
  border-bottom: none;
}
.select-field .list .list-entries .list__item:last-child:hover {
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.select-field .list .list-entries .list__item--black {
  border-bottom: 1px solid #4d4d4d;
}
.select-field .list .list-entries .list__item--black:hover {
  background-color: #333;
}
.select-field .list .list-entries .list__item--black .list__item:first-child {
  border-top: 1px solid #4d4d4d;
}
.select-field .list .list-entries .list__item--white {
  border-bottom: 1px solid #b3b3b3;
}
.select-field .list .list-entries .list__item--white:hover {
  background-color: #ccc;
}
.select-field .list .list-entries .list__item--white .list__item:first-child {
  border-top: 1px solid #b3b3b3;
}
.select-field .list .search-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
}
.select-field .list .search-input .input {
  padding: 2px;
  width: 85%;
  border: 0;
  background-color: #464749;
  color: #fff;
  font-size: 13px;
}
.select-field .list .search-input .add-button {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}
/*# sourceMappingURL=src/app/components/inputs/select-field.css.map */