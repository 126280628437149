









































































































































































































@import '~variables'

.select-field
  position relative
  border-radius $border-radius-smooth
  user-select none
  &--black
    background-color black
    color white
  &--white
    border 1px solid white
    background-color black
    color white
  .value
    z-index 2
    display flex
    padding 8px
    .select__icon
      margin-left auto
      width 15px
      cursor pointer
      transition transform 0.3s ease
      &--close
        transform rotate(180deg)
    &--empty
      height 30px
  .list
    position absolute
    top 100%
    left 0
    z-index 10
    display flex
    flex-direction column
    overflow-y auto
    margin-bottom 30px
    max-height 250px
    width 100%
    border-bottom solid 2px $silver
    border-radius $border-radius-smooth
    border-top-left-radius 0px
    border-top-right-radius 0px
    box-shadow $shadow-elevation-3
    user-select none
    &--white
      background-color white
      color black
    &--black
      background-color black
    .list-entries
      flex-grow 1
      overflow-y auto
      .list__item
        padding $space-1
        font-size $fs-h3
        cursor pointer
        &:last-child
          border-bottom none
          &:hover
            border-radius $border-radius-smooth
            border-top-left-radius 0px
            border-top-right-radius 0px
        &--black
          border-bottom 1px solid lighten(black, 30%)
          &:hover
            background-color lighten(black, 20%)
          .list__item:first-child
            border-top 1px solid lighten(black, 30%)
        &--white
          border-bottom 1px solid darken(white, 30%)
          &:hover
            background-color darken(white, 20%)
          .list__item:first-child
            border-top 1px solid darken(white, 30%)
    .search-input
      display flex
      justify-content center
      align-items center
      padding 5px 10px
      .input
        padding 2px
        width 85%
        border 0
        background-color $dark
        color white
        font-size 13px
      .add-button
        background-color white
        color black
        cursor pointer
