@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.chip {
  display: inline-flex;
  padding: 2px 8px;
  width: auto;
  background-color: #fff;
  color: #000;
  font-weight: 400;
  font-size: 0.8rem;
}
.chip--large {
  display: flex;
  font-size: 1rem;
}
.chip .chip__status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 15px;
}
.chip .chip__value {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
}
.chip .chip__value--large {
  justify-content: start;
  padding-left: 5px;
}
.chip .check-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd9d3;
  transition: all 0.3s ease;
}
.chip .check-circle--selected {
  background-color: #000;
}
/*# sourceMappingURL=src/app/components/ui/chip.css.map */