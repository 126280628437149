






















































@import '~variables'

.chip
  display inline-flex
  padding 2px 8px
  width auto
  background-color white
  color black
  font-weight 400
  font-size $fs-little
  &--large
    display flex
    font-size $fs-body
  .chip__status
    display flex
    justify-content flex-start
    align-items center
    width 15px
  .chip__value
    display flex
    flex 2
    justify-content center
    align-items center
    &--large
      justify-content start
      padding-left 5px
  .check-circle
    width 10px
    height @width
    border-radius 50%
    background-color $silver
    transition all 0.3s ease
    &--selected
      background-color black
